// https://frankindev.com/2017/03/18/syntax-highlight-with-rouge-in-jekyll/
.highlight,
.highlight pre,
.highlight table {
    background: #373b41;
    color: #555;
    border-radius: 4px;
}

pre > code {
    color: #969896;
}
.highlight .hll { background-color: #373b41 }
.highlight .c { color: #969896 } /* Comment */
.highlight .err { color: #cc6666 } /* Error */
.highlight .k { color: #b294bb } /* Keyword */
.highlight .l { color: #de935f } /* Literal */
.highlight .n, .highlight .h { color: #ffffff } /* Name */
.highlight .o { color: #8abeb7 } /* Operator */
.highlight .p { color: #ffffff } /* Punctuation */
.highlight .cm { color: #969896 } /* Comment.Multiline */
.highlight .cp { color: #969896 } /* Comment.Preproc */
.highlight .c1 { color: #969896 } /* Comment.Single */
.highlight .cs { color: #969896 } /* Comment.Special */
.highlight .gd { color: #cc6666 } /* Generic.Deleted */
.highlight .ge { font-style: italic } /* Generic.Emph */
.highlight .gh { color: #ffffff; font-weight: bold } /* Generic.Heading */
.highlight .gi { color: #b5bd68 } /* Generic.Inserted */
.highlight .gp { color: #969896; font-weight: bold } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #8abeb7; font-weight: bold } /* Generic.Subheading */
.highlight .kc { color: #b294bb } /* Keyword.Constant */
.highlight .kd { color: #b294bb } /* Keyword.Declaration */
.highlight .kn { color: #8abeb7 } /* Keyword.Namespace */
.highlight .kp { color: #b294bb } /* Keyword.Pseudo */
.highlight .kr { color: #b294bb } /* Keyword.Reserved */
.highlight .kt { color: #f0c674 } /* Keyword.Type */
.highlight .ld { color: #b5bd68 } /* Literal.Date */
.highlight .m { color: #de935f } /* Literal.Number */
.highlight .s { color: #b5bd68 } /* Literal.String */
.highlight .na { color: #81a2be } /* Name.Attribute */
.highlight .nb { color: #ffffff } /* Name.Builtin */
.highlight .nc { color: #f0c674 } /* Name.Class */
.highlight .no { color: #cc6666 } /* Name.Constant */
.highlight .nd { color: #8abeb7 } /* Name.Decorator */
.highlight .ni { color: #ffffff } /* Name.Entity */
.highlight .ne { color: #cc6666 } /* Name.Exception */
.highlight .nf { color: #81a2be } /* Name.Function */
.highlight .nl { color: #ffffff } /* Name.Label */
.highlight .nn { color: #f0c674 } /* Name.Namespace */
.highlight .nx { color: #81a2be } /* Name.Other */
.highlight .py { color: #ffffff } /* Name.Property */
.highlight .nt { color: #8abeb7 } /* Name.Tag */
.highlight .nv { color: #cc6666 } /* Name.Variable */
.highlight .ow { color: #8abeb7 } /* Operator.Word */
.highlight .w { color: #ffffff } /* Text.Whitespace */
.highlight .mf { color: #de935f } /* Literal.Number.Float */
.highlight .mh { color: #de935f } /* Literal.Number.Hex */
.highlight .mi { color: #de935f } /* Literal.Number.Integer */
.highlight .mo { color: #de935f } /* Literal.Number.Oct */
.highlight .sb { color: #b5bd68 } /* Literal.String.Backtick */
.highlight .sc { color: #ffffff } /* Literal.String.Char */
.highlight .sd { color: #969896 } /* Literal.String.Doc */
.highlight .s2 { color: #b5bd68 } /* Literal.String.Double */
.highlight .se { color: #de935f } /* Literal.String.Escape */
.highlight .sh { color: #b5bd68 } /* Literal.String.Heredoc */
.highlight .si { color: #de935f } /* Literal.String.Interpol */
.highlight .sx { color: #b5bd68 } /* Literal.String.Other */
.highlight .sr { color: #b5bd68 } /* Literal.String.Regex */
.highlight .s1 { color: #b5bd68 } /* Literal.String.Single */
.highlight .ss { color: #b5bd68 } /* Literal.String.Symbol */
.highlight .bp { color: #ffffff } /* Name.Builtin.Pseudo */
.highlight .vc { color: #cc6666 } /* Name.Variable.Class */
.highlight .vg { color: #cc6666 } /* Name.Variable.Global */
.highlight .vi { color: #cc6666 } /* Name.Variable.Instance */
.highlight .il { color: #de935f } /* Literal.Number.Integer.Long */
